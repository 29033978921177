
import { useStore } from "vuex";
import { ref, defineComponent, onMounted, computed } from "vue";
import { Actions, Modules } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "download",
  components: {},
  setup() {
    const store = useStore();

    let isLoading = computed(() => {
      return store.getters[Modules.EVENT + "getIsLoading"];
    });
    let events = ref([]);

    onMounted(() => {
      setCurrentPageTitle("All Events");
    });

    // Send login request
    store
      .dispatch(Modules.EVENT + Actions.FETCH_EVENTS)
      .then(() => {
        events.value = store.getters[Modules.EVENT + "getEvents"];
      })
      .catch(() => {
        const [error] = Object.keys(store.getters[Modules.EVENT + "getErrors"]);
      });
    return {
      isLoading,
      events,
      publicPath: process.env.BASE_URL,
      downloadWindowsURL: process.env.VUE_APP_DOWNLOAD_WINDOWS_URL,
      downloadMacOSURL: process.env.VUE_APP_DOWNLOAD_MACOS_URL,
    };
  },
});
